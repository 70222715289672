/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useDeviceType from '@/hooks/useDeviceType';
import { setOrderBy } from '@/redux/products/productsReducer';
import routerPushWithQuery from '@/utils/filters/routerPushWithQuery';
import { Select } from '@/components/atoms';

const SortSelect = (props) => {
  const { sortItems } = props;
  const dispatch = useDispatch();
  const { filtersActive, pagination } = useSelector((state) => state.products);

  const { isDesktop } = useDeviceType();
  // al seleccionar una opcion de "ordenar por"
  const handleClick = ({ value }) => {
    // si es mobile scrolleamos al inicio de la pagina
    if (!isDesktop) {
      window.scrollTo(0, 282);
    }

    // ejecutamos la accion de redux
    dispatch(setOrderBy(value));
    // hacemos el push a la nueva pagina con los filtros activados
    routerPushWithQuery(filtersActive, value, pagination.current);
  };
  const placeholder = useMemo(
    () =>
      sortItems.find((item) => item.id === filtersActive.ordenar)?.name ||
      'Ordenar por',
    [filtersActive]
  );
  return (
    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
      <div>
        <Select
          id="sortSelect_Filters"
          brColor="white"
          color="white"
          placeholder={placeholder}
          options={sortItems}
          onSelect={handleClick}
          variant="outlined"
          className="halfWidth"
        />
      </div>
    </div>
  );
};
SortSelect.propTypes = { sortItems: PropTypes.array.isRequired };

export default SortSelect;
