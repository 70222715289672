import SortSelect from '../SortSelect';
import styles from './index.module.scss';

const Sort = () => {
  const sortItems = [
    { id: 'menor_precio', name: 'Menor precio', value: 'menor_precio' },
    { id: 'mayor_precio', name: 'Mayor precio', value: 'mayor_precio' },
    { id: 'ofertas', name: 'Ofertas', value: 'ofertas' },
    { id: 'lanzamientos', name: 'Lanzamientos', value: 'lanzamientos' }
  ];
  return (
    <div className={styles.container}>
      <SortSelect sortItems={sortItems} />
    </div>
  );
};

Sort.propTypes = {};

export default Sort;
